import styled from "styled-components";
import { BodyText, SubheaderText, TitleText } from "../components/Text";
import { uid } from "../utils";
import { mediaQueries } from "../common/constants";
import Footer from "../components/Footer";

const MeetTheTeam = () => {
	const data = [
		{
			title: "Directors",
			portraits: [
				{
					text: `Hilary Price\nExecutive Director`,
					img: new URL(
						"/src/resources/img/20230621_063246.jpg",
						import.meta.url
					)
				},
				{
					text: "Lauren Liebling Davis\nNon-executive Director",
					img: new URL(
						"/src/resources/img/Lauren.png",
						import.meta.url
					)
				}
			]
		},
		{
			title: "Steering Group",
			text: `The Steering Group meets regularly to plan and discuss how the vision and mission of LSBP can be developed and realised in accordance with Biodynamic philosophy and values.`,
			portraits: [
				{
					text: "Anat Ben Israel",
					img: new URL(
						"/src/resources/img/Screen Shot 2022-08-31 at 23.55.20 2.jpeg",
						import.meta.url
					)
				},
				{
					text: "Carlien van Heel",
					img: new URL(
						"/src/resources/img/carlien.png",
						import.meta.url
					)
				},
				{
					text: "Hilary Price",
					img: new URL(
						"/src/resources/img/20230621_063246.jpg",
						import.meta.url
					)
				},
				{
					text: "Michelle Quoilin",
					img: new URL(
						"/src/resources/img/45224ae9-48a6-43cf-b6ef-106de3e77cee.jpg",
						import.meta.url
					)
				},
				{
					text: "Wolfgang Gerards",
					img: new URL(
						"/src/resources/img/Wolfgang.jpg",
						import.meta.url
					)
				}
			]
		},
		{
			title: "Training Board",
			text: `The Training Board is a group of senior trainers who meet regularly to plan, discuss and decide on the delivery of the Diploma Course and the Continuing Professional Development of Trainee Biodynamic Psychotherapists`,
			portraits: [
				{
					text: "Anat Ben Israel",
					img: new URL(
						"/src/resources/img/Screen Shot 2022-08-31 at 23.55.20 2.jpeg",
						import.meta.url
					)
				},
				{
					text: "Carlien van Heel",
					img: new URL(
						"/src/resources/img/carlien.png",
						import.meta.url
					)
				},
				{
					text: "Hilary Price",
					img: new URL(
						"/src/resources/img/20230621_063246.jpg",
						import.meta.url
					)
				},
				{
					text: "Michelle Quoilin",
					img: new URL(
						"/src/resources/img/45224ae9-48a6-43cf-b6ef-106de3e77cee.jpg",
						import.meta.url
					)
				},
				{
					text: "Siggi Bach",
					img: new URL(
						"/src/resources/img/Siggi.jpg",
						import.meta.url
					)
				}
			]
		},
		{
			title: "Support Staff",
			portraits: [
				{
					text: "Anwen O’Dell\nFinance Administrator",
					img: new URL(
						"/src/resources/img/Anwen.jpg",
						import.meta.url
					)
				},
				{
					text: "Helen England\nTraining Administrator",
					img: new URL(
						"/src/resources/img/Helen.jpeg",
						import.meta.url
					)
				},
				{
					text: "Toby Harrison\nPremises Manager",
					img: new URL(
						"/src/resources/img/Toby Harrison.jpg",
						import.meta.url
					)
				}
			]
		}
	];

	return (
		<>
			<TitleText>Meet The Team</TitleText>
			{data.map(({ title, text, portraits }) => {
				return (
					<div key={uid()}>
						<StyledSubheader>{title}</StyledSubheader>
						{text ? (
							<CenteredBodyText>{text}</CenteredBodyText>
						) : null}
						<FlexContainer>
							{portraits.map(({ img, text }) => {
								return (
									<div key={uid()}>
										<Portrait key={uid()} src={img} />
										{text ? (
											<CenteredBodyText>
												{text}
											</CenteredBodyText>
										) : null}
									</div>
								);
							})}
						</FlexContainer>
					</div>
				);
			})}
			<Footer />
		</>
	);
};

export default MeetTheTeam;

/** Other Components */

/** Styled Components */

const Portrait = styled.img`
	display: block;
	object-fit: cover;
	object-position: top;
	width: 180px;
	height: 300px;
	margin: 30px 60px 0;

	${mediaQueries.extraSmallScreens} {
		width: 200px;
		height: 250px;
		margin: 0 auto;
	}
`;

const FlexContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;

const StyledSubheader = styled(SubheaderText)`
	margin: 30px 0;
`;

const CenteredBodyText = styled(BodyText)`
	text-align: center;
	white-space: break-spaces;
`;
