import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import styled from "styled-components";
import FindATherapist from "./pages/FindATherapist";
import TrainingAndWorkshops from "./pages/TrainingAndWorkshops";
import ContactUs from "./pages/ContactUs";
import { mediaQueries } from "./common/constants";
import PageNotFound from "./pages/404";
import MeetTheTeam from "./pages/MeetTheTeam";

function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Navbar />
				<ContentColumn>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route
							path="/find-a-therapist"
							element={<FindATherapist />}
						/>
						<Route
							path="/training-and-workshops"
							element={<TrainingAndWorkshops />}
						/>
						<Route path="/contact" element={<ContactUs />} />
						<Route
							path="/meet-the-team"
							element={<MeetTheTeam />}
						/>
						<Route path="/*" element={<PageNotFound />} />
					</Routes>
				</ContentColumn>
			</div>
		</BrowserRouter>
	);
}

export default App;

const ContentColumn = styled.div`
	width: 95%;
	${mediaQueries.extraLargeScreens} {
		width: 80%;
	}
	${mediaQueries.largeScreens} {
		width: 90%;
	}
	margin: 0 auto;
`;
