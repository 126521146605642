import styled from "styled-components";
import { BodyText, TitleText } from "../components/Text";
import { colours, mediaQueries } from "../common/constants";
import Footer from "../components/Footer";

const ContactUs = () => {
	return (
		<>
			<TitleText>Contact Us</TitleText>
			<CenteredText>
				Join our mailing list to receive information and updates about
				our courses and events.
			</CenteredText>
			<CenteredText>
				For help with anything else, please email us
			</CenteredText>
			<CenteredText>
				Email:{" "}
				<EmailLink href="mailto:email.lsbp@gmail.com">
					email.lsbp@gmail.com
				</EmailLink>
			</CenteredText>

			<MailChimp />

			<Footer />
		</>
	);
};

export default ContactUs;

/** Other Components */

const MailChimp = () => {
	const embeddedHTML = `<div id="mc_embed_shell">
	<link
		href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
		rel="stylesheet"
		type="text/css" />
	<style type="text/css">
		#mc_embed_signup{background:#fff; margin:auto; false;clear:left; font:14px Helvetica,Arial,sans-serif;}
		/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
		We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */</style
	><style type="text/css">
		#mc-embedded-subscribe-form input[type="checkbox"] {
			display: inline;
			width: auto;
			margin-right: 10px;
		}
		#mergeRow-gdpr {
			margin-top: 20px;
		}
		#mergeRow-gdpr fieldset label {
			font-weight: normal;
		}
		#mc-embedded-subscribe-form .mc_fieldset {
			border: none;
			min-height: 0px;
			padding-bottom: 0px;
		}
	</style>
	<div id="mc_embed_signup">
		<form
			action="https://centreforbodypsychotherapy.us18.list-manage.com/subscribe/post?u=5e5090a307430b981dec344c1&amp;id=5106ecd63d&amp;v_id=5735&amp;f_id=00251ae7f0"
			method="post"
			id="mc-embedded-subscribe-form"
			name="mc-embedded-subscribe-form"
			class="validate"
			target="_blank">
			<div id="mc_embed_signup_scroll">
				<h2>Add me to the LSBP mailing list.</h2>
				<div class="indicates-required">
					<span class="asterisk">*</span> indicates required
				</div>
				<div class="mc-field-group">
					<label for="mce-EMAIL"
						>Email Address <span class="asterisk">*</span></label
					><input
						type="email"
						name="EMAIL"
						class="required email"
						id="mce-EMAIL"
						required=""
						value="" />
				</div>
				<div class="mc-field-group">
					<label for="mce-FNAME"
						>First Name <span class="asterisk">*</span></label
					><input
						type="text"
						name="FNAME"
						class="required text"
						id="mce-FNAME"
						required=""
						value="" />
				</div>
				<div class="mc-field-group">
					<label for="mce-LNAME"
						>Last Name <span class="asterisk">*</span></label
					><input
						type="text"
						name="LNAME"
						class="required text"
						id="mce-LNAME"
						required=""
						value="" />
				</div>
				<div
					id="mergeRow-gdpr"
					class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
					<div class="content__gdpr">
						<label>Marketing Permissions</label>
						<p>
							London School of Biodynamic Psychotherapy will use the
							information you provide on this form to be in touch
							with you and to provide updates and marketing, via:
						</p>
						<fieldset
							class="mc_fieldset gdprRequired mc-field-group"
							name="interestgroup_field">
							<label class="checkbox subfield" for="gdpr27"
								><input
									type="checkbox"
									id="gdpr_27"
									name="gdpr[27]"
									class="gdpr"
									value="Y" /><span>Email</span></label
							>
						</fieldset>
						<p>
							You can change your mind at any time by clicking the
							unsubscribe link in the footer of any email you
							receive from us, or by contacting us at
							email.lsbp@gmail.com. We will treat your information
							with respect. For more information about our privacy
							practices please visit our website. By clicking
							below, you agree that we may process your
							information in accordance with these terms.
						</p>
					</div>
					<div class="content__gdprLegal">
						<p>
							We use Mailchimp as our marketing platform. By
							clicking below to subscribe, you acknowledge that
							your information will be transferred to Mailchimp
							for processing.
							<a href="https://mailchimp.com/legal/terms"
								>Learn more</a
							>
							about Mailchimp's privacy practices.
						</p>
					</div>
				</div>
				<div hidden="">
					<input type="hidden" name="tags" value="43863" />
				</div>
				<div id="mce-responses" class="clear foot">
					<div
						class="response"
						id="mce-error-response"
						style="display: none"></div>
					<div
						class="response"
						id="mce-success-response"
						style="display: none"></div>
				</div>
				<div
					aria-hidden="true"
					style="position: absolute; left: -5000px">
					/* real people should not fill this in and expect good
					things - do not remove this or risk form bot signups */
					<input
						type="text"
						name="b_5e5090a307430b981dec344c1_5106ecd63d"
						tabindex="-1"
						value="" />
				</div>
				<div class="optionalParent">
					<div class="clear foot">
						<input
							type="submit"
							name="subscribe"
							id="mc-embedded-subscribe"
							class="button"
							value="Subscribe" />
						<p style="margin: 0px auto">
							<a
								href="http://eepurl.com/h1csVP"
								title="Mailchimp - email marketing made easy and fun"
								><span
									style="
										display: inline-block;
										background-color: transparent;
										border-radius: 4px;
									"
									><img
										class="refferal_badge"
										src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
										alt="Intuit Mailchimp"
										style="
											width: 220px;
											height: 40px;
											display: flex;
											padding: 2px 0px;
											justify-content: center;
											align-items: center;
										" /></span
							></a>
						</p>
					</div>
				</div>
			</div>
		</form>
	</div>
	<script
		type="text/javascript"
		src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
	<script type="text/javascript">
		(function ($) {
			window.fnames = new Array();
			window.ftypes = new Array();
			fnames[0] = "EMAIL";
			ftypes[0] = "email";
			fnames[1] = "FNAME";
			ftypes[1] = "text";
			fnames[2] = "LNAME";
			ftypes[2] = "text";
			fnames[3] = "ADDRESS";
			ftypes[3] = "address";
			fnames[4] = "PHONE";
			ftypes[4] = "phone";
			fnames[5] = "BIRTHDAY";
			ftypes[5] = "birthday";
			fnames[6] = "MMERGE6";
			ftypes[6] = "text";
			fnames[7] = "MMERGE7";
			ftypes[7] = "text";
		})(jQuery);
		var $mcj = jQuery.noConflict(true);
	</script>
</div>
`;

	return (
		<MailChimpContainter
			dangerouslySetInnerHTML={{ __html: embeddedHTML }}
		/>
	);
};

/** Styled Components */

const CenteredText = styled(BodyText)`
	text-align: center;
	line-height: 2em;
`;

const EmailLink = styled.a`
	color: ${colours.purple};
`;

const MailChimpContainter = styled.div`
	width: 50%;
	margin: 40px auto 0;

	${mediaQueries.smallScreensAndSmaller} {
		width: 100%;
	}
`;
