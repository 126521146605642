/** Areas which therapists can be categorised into */

const areas = [
	"London",
	"East/South East",
	"Midlands",
	"South West",
	"International",
	"Online"
];

export default areas;
