const mediaQueries = {};
mediaQueries.extraSmallScreens = `@media (max-width: 575px)`;
mediaQueries.smallScreens = `@media (min-width: 576px) and (max-width: 767px)`;
mediaQueries.smallScreensAndSmaller = `@media (max-width: 767px)`;
mediaQueries.mediumScreens = `@media (min-width: 768px) and (max-width: 991px)`;
mediaQueries.mediumScreensAndSmaller = `@media (max-width: 991px)`;
mediaQueries.largeScreens = `@media (min-width: 992px) and (max-width: 1199px)`;
mediaQueries.extraLargeScreens = `@media (min-width: 1200px)`;

const colours = {
	white: "#F5F5F5",
	purple: "#323391",
	text: "#3a3b3c"
};

export { colours, mediaQueries };
