import styled from "styled-components";
import { colours } from "../common/constants";

const HorizontalRule = styled.div`
	background-color: ${colours.text};
	height: 2px;
	width: 100%;
	margin: 40px 0;
`;

export { HorizontalRule };
