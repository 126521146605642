import styled from "styled-components";
import { BodyText, SubheaderText, TitleText } from "../components/Text";
import { colours, mediaQueries } from "../common/constants";
import { HorizontalRule } from "../components/Decorations";
import imgOne from "../resources/img/2158abd9-1fc8-4fda-a051-2be7b29bdf16.jpg";
import imgTwo from "../resources/img/IMG_1078-3-1024x559.jpg";
import imgThree from "../resources/img/IMG_1077-2-1024x571.jpg";
import imgFour from "../resources/img/c8865673-a37c-4075-ba05-0890fd628d08-1.jpg";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const TrainningAndWorkshops = () => {
	const navigate = useNavigate();
	const handleClick = (path) => {
		navigate(path);
		window.scrollTo(0, 0);
	};

	return (
		<>
			<TitleText>Training and Workshops</TitleText>
			<CenteredText>
				“Biodynamic psychotherapy is the life work of Gerda Boyesen
				(1922 – 2005), the Norwegian psychologist and physiotherapist.
				It is based on a profound appreciation of the oneness of a
				person's body/mind/spirit, and integrates an enormously broad
				range of methods, addressing body and mind as one. We work not
				only with talking, but also with movements – both subtle and
				strong – and with many levels of 'hands-on' work, as well as
				with meditative and sensory awareness.”
				<br />
				<br />
				Clover Southwell, 2001
			</CenteredText>
			<PromoCardContainer>
				<PromoCard>
					<PromoCardHeaderText>
						Biodynamic Psychotherapy
						<br />
						Diploma Course
					</PromoCardHeaderText>
					<PromoCardBodyText>
						We are the only school offering a UKCP and EABP
						accredited 4 year part-time Diploma Course in Biodynamic
						Psychotherapy. Body oriented therapies are in great
						demand and our qualification prepares you for rich and
						rewarding work in private practice or employment.
					</PromoCardBodyText>
					<PromoButton
						onClick={() =>
							window.open(
								"https://docs.google.com/document/d/14rvHhsJpLuN0MF1SI0hK0Bws34lrN2x8qgbAR0z25MQ/edit",
								"_blank"
							)
						}>
						Diploma Course Details
					</PromoButton>
				</PromoCard>
				<PromoCard>
					<PromoCardHeaderText>
						Workshops and
						<br />
						Open Evenings
					</PromoCardHeaderText>
					<PromoCardBodyText>
						Our public workshops are open to anyone with an interest
						in the field and a prerequisite to join our Diploma
						Course training. Open evenings are an opportunity to
						meet the trainers and find out more about the course.
						Visit our events page below for more information and
						bookings.
					</PromoCardBodyText>
					<PromoButton
						onClick={() =>
							window.open(
								"https://www.tickettailor.com/events/lsbp",
								"_blank"
							)
						}>
						Events
					</PromoButton>
				</PromoCard>
			</PromoCardContainer>
			<HorizontalRule />
			<ImageBoard>
				<ImageColumn>
					<Img src={imgOne} />
				</ImageColumn>
				<ImageColumn>
					<Img src={imgTwo} />
					<Img src={imgThree} />
				</ImageColumn>
				<ImageColumn>
					<Img src={imgFour} />
				</ImageColumn>
			</ImageBoard>
			<ImageColumnForMobile>
				<Img src={imgOne} />
				<Img src={imgTwo} />
				<Img src={imgThree} />
				<Img src={imgFour} />
			</ImageColumnForMobile>
			<HorizontalRule />
			<CenteredText>
				Join our mailing list to receive information and updates about
				our courses and events.
			</CenteredText>
			<br />
			<PromoButton onClick={() => handleClick("/contact")}>
				Contact Us
			</PromoButton>
			<Footer />
		</>
	);
};

export default TrainningAndWorkshops;

const CenteredText = styled(BodyText)`
	text-align: center;
	line-height: 1.4em;
`;

const PromoCardContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-top: 60px;
`;

const PromoCard = styled.div`
	row-gap: 40px;
	justify-content: space-between;
	margin-top: 50px;
	width: 50%;
	min-width: 300px;
`;

const PromoCardHeaderText = styled(SubheaderText)`
	font-size: 20pt;
`;

const PromoCardBodyText = styled(CenteredText)`
	margin-top: 20px;
	height: 150px;
`;

const PromoButton = styled(BodyText)`
	text-align: center;
	margin: 0 auto;
	padding: 15px;
	width: 200px;
	background-color: ${colours.purple};
	color: ${colours.white};
	box-shadow: 1px 1px 4px ${colours.text};
	&:hover {
		cursor: pointer;
	}
`;

const ImageBoard = styled.div`
	display: flex;
	justify-content: space-evenly;
	${mediaQueries.smallScreensAndSmaller} {
		display: none;
	}
`;

const ImageColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 33%;
	height: 600px;
`;

const ImageColumnForMobile = styled.div`
	display: none;

	${mediaQueries.smallScreensAndSmaller} {
		display: block;
		margin: 20px auto;
	}
`;

const Img = styled.img`
	box-shadow: 0px 0px 2px ${colours.purple};
	${mediaQueries.smallScreensAndSmaller} {
		width: 80%;
		display: block;
		margin: 10px auto;
	}
`;
