import styled from "styled-components";
import { BodyText, SubheaderText, TitleText } from "../components/Text";
import therapistsImg from "../resources/img/PHOTO-2023-10-22-20-38-00.jpg";
import birdImg from "../resources/img/P1030573-300x225.jpg";
import poppyImg from "../resources/img/P1040302-scaled.jpg";
import lillyImg from "../resources/img/b9312711-91bc-4de7-804f-116a818c2049.jpg";
import headMassageImg from "../resources/img/unnamed-300x206.jpg";
import { colours, mediaQueries } from "../common/constants";
import { useNavigate } from "react-router-dom";
import { HorizontalRule } from "../components/Decorations";
import Footer from "../components/Footer";

const Home = () => {
	const navigate = useNavigate();
	const handleClick = (path) => {
		navigate(path);
		window.scrollTo(0, 0);
	};

	return (
		<>
			<TitleText>London School of Biodynamic Psychotherapy</TitleText>
			<MainImg src={therapistsImg} alt="therapists of LSBP"></MainImg>
			<HorizontalRule />
			<PromotionCardContainer>
				<TextContainer>
					<SubheaderText>Training and Workshops</SubheaderText>
					<PromoCardText>
						We offer a four-year part-time post-graduate Diploma
						Course in Biodynamic Psychotherapy as well as Workshops
						and Open Evenings.
					</PromoCardText>
					<Button
						onClick={() => handleClick("/training-and-workshops")}>
						Explore Training
					</Button>
				</TextContainer>
				<PromotionCardImage src={birdImg} />
			</PromotionCardContainer>
			<HorizontalRule />
			<TextInImageBanner imgurl={poppyImg}>
				<TextBox>
					Our members offer individual Biodynamic Psychotherapy,
					<br />
					Biodynamic Massage and Supervision.
					<br />
					Find a therapist or supervisor here.
				</TextBox>
				<Button onClick={() => handleClick("/find-a-therapist")}>
					Find A Therapist
				</Button>
			</TextInImageBanner>
			<HorizontalRule />
			<PromotionCardContainer>
				<TextContainer>
					<SubheaderText>Biodynamic Massage Clinic</SubheaderText>
					<PromoCardText>
						Our students offer low-cost Biodynamic Massage sessions
						under live supervision. Book a massage session{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.tickettailor.com/events/lsbp">
							<Link>here.</Link>
						</a>
					</PromoCardText>
					<Button
						onClick={() =>
							window.open(
								"https://www.tickettailor.com/events/lsbp",
								"_blank"
							)
						}>
						Book A Massage
					</Button>
				</TextContainer>
				<PromotionCardImage src={headMassageImg} />
			</PromotionCardContainer>
			<HorizontalRule />
			<TextInImageBanner imgurl={lillyImg}>
				<TextBox>
					For more information on Workshops, Open Evenings and other
					events
				</TextBox>
				<Button onClick={() => handleClick("/contact")}>
					Contact Us
				</Button>
			</TextInImageBanner>
			<Footer />
		</>
	);
};

export default Home;

const MainImg = styled.img`
	display: block;
	margin: 100px auto;
	width: 750px;
	max-width: 80%;
	@media (max-width: 768px) {
		width: 500px;
	}
`;

const PromotionCardContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	padding: 20px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	flex: 0 0 50%;

	${mediaQueries.smallScreensAndSmaller} {
		margin: auto;
	}
`;

const PromoCardText = styled(BodyText)`
	margin: 40px 0;
`;

const Link = styled.span`
	text-decoration: underline;
	color: ${colours.purple};
	&:hover {
		cursor: pointer;
	}
`;

const Button = styled(BodyText)`
	text-align: center;
	margin: 0 auto;
	padding: 15px 0;
	width: 200px;
	background-color: ${colours.purple};
	color: ${colours.white};
	box-shadow: 1px 1px 4px ${colours.text};
	&:hover {
		cursor: pointer;
	}
`;

const PromotionCardImage = styled.img`
	flex: 0 0 300px;
	object-fit: contain;

	${mediaQueries.smallScreensAndSmaller} {
		display: none;
	}
`;

const TextInImageBanner = styled.div`
	height: 300px;
	width: 100%;
	background-image: url(${(props) => props.imgurl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

const TextBox = styled(BodyText)`
	text-align: center;
	width: fit-content;
	margin: 0 auto;
	font-weight: bold;
	font-size: 20pt;
	background-color: #f5f5f5d1;
	${mediaQueries.smallScreensAndSmaller} {
		font-size: 14pt;
	}
`;
