import styled from "styled-components";
import ambtLogo from "../resources/img/ambt-logo-e1689872214369.jpg";
import ukcpLogo from "../resources/img/ukcp-logo.jpg";
import eabpLogo from "../resources/img/eabp-logo-e1689872142131.png";
import { uid } from "../utils";
import { BodyText } from "./Text";
import { mediaQueries } from "../common/constants";

const Footer = () => {
	const logos = [ambtLogo, ukcpLogo, eabpLogo];
	return (
		<>
			<LogosContainer>
				{logos.map((logo) => {
					return <Logo key={uid()} src={logo} />;
				})}
			</LogosContainer>
			<CopyrightText>
				© all rights reserved to the London School of Biodynamic
				Psychotherapy, 2024
			</CopyrightText>
		</>
	);
};

export default Footer;

const LogosContainer = styled.div`
	width: 80%;
	display: flex;
	margin: 50px auto 0;
	justify-content: space-around;
	flex-wrap: wrap;

	${mediaQueries.smallScreensAndSmaller} {
		flex-direction: column;
	}
`;

const Logo = styled.img`
	width: 250px;
	height: 150px;
	max-width: 90%;
	object-fit: contain;

	${mediaQueries.mediumScreensAndSmaller} {
		width: 150px;
		height: fit-content;
		margin: 5px auto;
	}
`;

const CopyrightText = styled(BodyText)`
	text-align: center;
	margin: 20px;
`;
