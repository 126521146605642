const therapists = [
	{
		name: "Jonathan Dicker",
		qualifications: ["Biodynamic Psychotherapist"],
		location: ["London", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["Jonathan.biodynamic@gmail.com"],
		imgUrl: new URL("/src/resources/img/image_6487327.JPG", import.meta.url)
	},
	{
		name: "Liz Introno",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: [
			"London Central",
			"London South",
			"SE16",
			"Works in-person and online"
		],
		area: ["London", "Online"],
		links: ["eintrono@gmail.com", "https://www.lizbodypsychotherapy.com"],
		imgUrl: new URL(
			"/src/resources/img/2B04204A-A622-48B7-B92F-FB5BFB99BC7C_1_201_a.jpeg",
			import.meta.url
		)
	},
	{
		name: "Catherine Gehin",
		qualifications: ["Trainee Biodynamic Psychotherapist"],
		location: ["London West (W10)", "Works in-person"],
		links: [
			"massagetherapy.biodynamic@gmail.com",
			"https://www.nottinghilltherapyclinic.com"
		],
		area: ["London"],
		imgUrl: new URL("/src/resources/img/IMG_5743.png", import.meta.url)
	},
	{
		name: "Sahar Talaat Elbassiony",
		qualifications: [
			"Advanced Trainee Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist."
		],
		location: ["London South CR0", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["sahartalaat.psychotherapist@gmail.com"],
		imgUrl: new URL(
			"/src/resources/img/thumbnail_DSC_9754 copy - Copy.jpg",
			import.meta.url
		)
	},
	{
		name: "Françoise Lauwerie",
		qualifications: [
			"Trainee Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: [
			"London South West (SW11, SW15)",
			"Works in-person and online"
		],
		area: ["London", "Online"],
		links: ["francoise.lauwerie@laposte.net"],
		imgUrl: new URL(
			"/src/resources/img/Headshot  Francoise Lauwerie.jpg",
			import.meta.url
		)
	},
	{
		name: "Silvia Manzoni",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London West (W6)", "Works in-person"],
		area: ["London"],
		links: ["man.silvia@gmail.com"],
		imgUrl: new URL("/src/resources/img/image0 (2).jpeg", import.meta.url)
	},
	{
		name: "Tasha Payne",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London South", "Works in-person"],
		area: ["London"],
		links: ["tash.biodynamictherapy@gmail.com"],
		imgUrl: new URL(
			"/src/resources/img/PXL_20230610_150928037~2.jpg",
			import.meta.url
		)
	},
	{
		name: "Carlo Volpi",
		qualifications: ["Trainee Biodynamic Psychotherapist"],
		location: ["London North (N10)", "Works in-person"],
		area: ["London"],
		links: ["biodynamic.carlo@gmail.com", "https://www.soundstasis.com"],
		imgUrl: new URL("/src/resources/img/IMG_1576.JPG", import.meta.url)
	},
	{
		name: "Delene Gordon",
		qualifications: [
			"Biodynamic Massage Therapist",
			"Trainee Biodynamic Psychotherapist"
		],
		location: ["London East (E8/E10)", "Works in-person"],
		area: ["London"],
		links: ["delenedoes2@gmail.com"],
		imgUrl: new URL("/src/resources/img/_ASH6787.jpg", import.meta.url)
	},
	{
		name: "Maryam Abdollahzadeh",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London, Reading RG2", "Works in-person"],
		area: ["London", "South West"],
		links: ["abdoll.mar@gmail.com"],
		imgUrl: new URL("/src/resources/img/IMG_1386.JPG", import.meta.url)
	},
	{
		name: "Antoaneta Mandajieva",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London, South West", "Works in-person"],
		area: ["London"],
		links: ["Antoanetawhale@hotmail.co.uk"],
		imgUrl: new URL("/src/resources/img/image2.jpeg", import.meta.url)
	},
	{
		name: "Shaun Brooking",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London West (W1U)", "Works in-person"],
		area: ["London"],
		links: [
			"info@shaunbrookingcoaching.co.uk",
			"https://www.shaunbrookingcoaching.co.uk"
		],
		imgUrl: new URL(
			"/src/resources/img/Shaun-Brooking.webp",
			import.meta.url
		)
	},
	{
		name: "Jessica Brown",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London", "Works in-person"],
		area: ["London"],
		links: [
			"trustinthelifeforce@gmail.com",
			"https://jessicabrownbiodynamic.my.canva.site/"
		],
		imgUrl: new URL("/src/resources/img/unnamed (1).jpg", import.meta.url)
	},
	{
		name: "Marleen Joseph",
		qualifications: ["Biodynamic Massage Therapist"],
		location: [
			"London South West (SW19, SW20, SM3, SM4)",
			"Works in-person"
		],
		area: ["London"],
		links: ["marljoe@hotmail.com"],
		imgUrl: new URL(
			"/src/resources/img/20161115_202328-1.jpg",
			import.meta.url
		)
	},
	{
		name: "Barbara Hussong",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["South East (ME16)", "Works in-person and online"],
		area: ["East/South East", "Online"],
		links: [
			"info@barbarahussong.co.uk",
			"https://www.barbarahussong.co.uk"
		],
		imgUrl: new URL(
			"/src/resources/img/Barbara Hussong March 2016.JPG",
			import.meta.url
		)
	},
	{
		name: "Catherine Rees",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["South East (BN7)", "Works in-person"],
		area: ["East/South East"],
		links: [
			"Catherinemrees@ymail.com",
			"https://www.integrative-psychotherapist.org"
		],
		imgUrl: new URL("/src/resources/img/catherine 1st.JPG", import.meta.url)
	},
	{
		name: "Michelle Quoilin Lebrun",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["Surrey (GU2)", "Works in-person"],
		area: ["East/South East"],
		links: ["mquoilin_lebrun@yahoo.co.uk"],
		imgUrl: new URL(
			"/src/resources/img/45224ae9-48a6-43cf-b6ef-106de3e77cee.jpg",
			import.meta.url
		)
	},
	{
		name: "Nicola Jephson Coates",
		qualifications: [
			"Trainee Biodynamic Psychother",
			"Biodynamic Massage Therapist"
		],
		location: ["South East, Hindhead/Haslemere (GU26)", "Works in-person"],
		area: ["East/South East"],
		links: [
			"Najephsoncoates@gmail.com",
			"https://www.nicolabodypsychotherapy.com"
		],
		imgUrl: new URL(
			"/src/resources/img/IMG_20230621_165112_edit_370921201998086 2.jpg",
			import.meta.url
		)
	},
	{
		name: "Rowena Shingler",
		qualifications: [
			"Advanced Trainee Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["Surrey GU15", "Works in-person and online"],
		area: ["East/South East", "Online"],
		links: [
			"rowena.shingler@yahoo.co.uk",
			"https://www.rowenashinglerbiodynamictherapist.com"
		],
		imgUrl: new URL("/src/resources/img/image0 (1).jpeg", import.meta.url)
	},
	{
		name: "Evodia Monica Martin",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["East Sussex (Brighton, Hove)", "Works in-person"],
		area: ["East/South East"],
		links: ["Evodiamonica@gmail.com"],
		imgUrl: new URL(
			"/src/resources/img/Screenshot_20230626-084852_Instagram.jpg",
			import.meta.url
		)
	},
	{
		name: "Steffany George",
		qualifications: [
			"Biodynamic Massage Therapist",
			"Trainee Biodynamic Psychotherapist"
		],
		location: ["Southend-on-Sea Essex", "Works in-person"],
		area: ["East/South East"],
		links: ["segeorge7@gmail.com"],
		imgUrl: new URL("/src/resources/img/image0 (3).jpeg", import.meta.url)
	},
	{
		name: "Leslie Laine",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["South East (BN3) ", "Works in-person"],
		area: ["East/South East"],
		links: ["leslielaine@icloud.com ", "https://www.soul-arena.co.uk"],
		imgUrl: new URL("/src/resources/img/IMG_1845.jpg", import.meta.url)
	},
	{
		name: "Melita Pupsaite",
		qualifications: ["Trainee Biodynamic Psychotherapist"],
		location: ["London (E8), Hertfordshire (CM23)", "Works in-person"],
		area: ["London", "East/South East"],
		links: ["melitapupsaite.co.uk"],
		imgUrl: new URL("/src/resources/img/lsbp_melita2.jpg", import.meta.url)
	},
	{
		name: "Ingrid Wienholts",
		qualifications: [
			"Biodynamic Massage Therapist, Trainee Biodynamic Psychotherapist"
		],
		location: ["North Hampshire", "Works in-person"],
		area: ["East/South East"],
		links: ["ingrid.wienholts@yahoo.com"],
		imgUrl: new URL(
			"/src/resources/img/IMG-20220614-WA0009.jpg",
			import.meta.url
		)
	},
	{
		name: "Toby Harrison",
		qualifications: ["Trainee Biodynamic Psychotherapist"],
		location: ["Central and West London", "Works in-person"],
		area: ["London"],
		links: [
			"tobes2002@hotmail.com",
			"https://www.AutonomicWellbeing.co.uk"
		],
		phone: "07894581996",
		imgUrl: new URL("/src/resources/img/Toby_image1.jpg", import.meta.url)
	},
	// {
	// 	name: "Janet Sutherland",
	// 	qualifications: ["Biodynamic Psychotherapist"],
	// 	location: ["Lincolnshire (NG34)", "Works in-person"],
	// 	area: ["Midlands"],
	// 	links: ["Jansutherland0@yahoo.co.uk"],
	// 	imgUrl: new URL(
	// 		"/src/resources/img/generic-user-icon-19.png",
	// 		import.meta.url
	// 	)
	// },
	{
		name: "Gemma Ireland",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist",
			"Supervisor"
		],
		location: ["South West (BA1)", "Works in-person and online"],
		area: ["South West", "Online"],
		links: ["gemma3@talktalk.net"],
		imgUrl: new URL("/src/resources/img/IMG_0070.JPG", import.meta.url)
	},
	{
		name: "Gillie Gilbert",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["Dorset", "Works online"],
		area: ["South West", "Online"],
		links: ["gilliegilbert@gmail.com"],
		imgUrl: new URL("/src/resources/img/GillieG.jpg", import.meta.url)
	},
	{
		name: "Marc Wallach",
		qualifications: ["Trainee Biodynamic Psychotherapist"],
		location: ["South West (Devon)", "Works in-person"],
		area: ["South West"],
		links: [
			"marcwallach68@gmail.com",
			"https://marcbodypsychotherapy.com/"
		],
		imgUrl: new URL("/src/resources/img/marc.jpeg", import.meta.url)
	},
	{
		name: "Nechoma Schwab",
		qualifications: ["Biodynamic Psychotherapist"],
		location: ["Israel", "Works in-person and online"],
		area: ["International", "Online"],
		links: ["nechoma@mpowerlife.co.uk", "https://www.mpowerlife.co.uk"],
		imgUrl: new URL("/src/resources/img/nechoma.jpg", import.meta.url)
	},
	{
		name: "Terezinha Sell",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: [
			"Ireland (Galway City, Kinvara)",
			"Works in-person and online"
		],
		area: ["International", "Online"],
		links: ["te@tracht.ie", "https://www.tracht.ie"],
		imgUrl: new URL(
			"/src/resources/img/210629 Photo TS.jpg",
			import.meta.url
		)
	},
	{
		name: "Olivia Lumley",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London SE5", "Works in-person"],
		area: ["London"],
		links: [
			"olivialumleytherapies@gmail.com",
			"https://www.olivialumleytherapies.co.uk"
		],
		imgUrl: new URL(
			"/src/resources/img/2023-12-13 Olivia x Mandrake 0A6A9532.jpeg",
			import.meta.url
		)
	},
	{
		name: "Hilary Price",
		qualifications: ["Biodynamic Psychotherapist"],
		location: ["Works online"],
		area: ["Online"],
		links: [
			"hilary@biodynamictherapy.co.uk",
			"https://www.biodynamictherapy.co.uk"
		],
		imgUrl: new URL(
			"/src/resources/img/20230621_063246.jpg",
			import.meta.url
		)
	},
	{
		name: "Brigitte Scott-Florek",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["Hampshire and East Dorset", "Works in-person and online"],
		area: ["East/South East", "South West", "Online"],
		links: [
			"Brigittescottflorek@gmail.com",
			"https://www.brigittescottflorek.co.uk"
		],
		imgUrl: new URL(
			"/src/resources/img/20230625_193332.jpg",
			import.meta.url
		)
	},
	{
		name: "Claudia de Campos",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["London (NW3)", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["claudia.campos@hotmail.co.uk"],
		imgUrl: new URL("/src/resources/img/claudia.jpg", import.meta.url)
	},
	{
		name: "Deborah Webb",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["South West (BA2)", "Works in-person and online"],
		area: ["South West", "Online"],
		links: ["deborahwebb@talktalk.net"],
		imgUrl: new URL(
			"/src/resources/img/PHOTO-2023-11-21-17-42-40.jpg",
			import.meta.url
		)
	},
	{
		name: "Anat Ben-Israel",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["USA, UK, Israel", "Works in-person and online"],
		area: ["International", "Online"],
		links: ["anatbenisrael@gmail.com", "https://www.biodynamictherapy.net"],
		imgUrl: new URL(
			"/src/resources/img/Screen Shot 2022-08-31 at 23.55.20 2.jpeg",
			import.meta.url
		)
	},
	{
		name: "Yigal Israel",
		qualifications: ["Biodynamic Psychotherapist"],
		location: ["Israel", "Works in-person and online"],
		area: ["International", "Online"],
		links: ["yigalisr@gmail.com"],
		imgUrl: new URL(
			"/src/resources/img/Yigal Israel Photo June 2019 more light.jpg",
			import.meta.url
		)
	},
	{
		name: "Sophie Paine",
		qualifications: [
			"Advanced Trainee Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["Gloucestershire (GL6)", "Works in-person and online"],
		area: ["South West", "Online"],
		links: ["sophie@wisebodies.com", "https://www.wisebodies.com"],
		imgUrl: new URL("/src/resources/img/IMG_3574.jpg", import.meta.url)
	},
	{
		name: "Julia Schaper",
		qualifications: [
			"Trainee Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["London South (SW8)", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["juliaschaper@gmx.co.uk"],
		imgUrl: new URL(
			"/src/resources/img/IMG_20230624_161406155.jpg",
			import.meta.url
		)
	},
	{
		name: "Emma Bond",
		qualifications: [
			"Advanced Trainee Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: [
			"London Central (NW1), Suffolk (IP12)",
			"Works in-person and online"
		],
		area: ["London", "East/South East", "Online"],
		links: [
			"info@yogawithemmabond.com",
			"https://emmabondbodypsychotherapist.com"
		],
		imgUrl: new URL("/src/resources/img/IMG_0412.jpg", import.meta.url)
	},
	{
		name: "Mélanie d’Ursel",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["London West (W11)", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["melanie@therapy.dursel.com"],
		imgUrl: new URL("/src/resources/img/image0.jpeg", import.meta.url)
	},
	{
		name: "Shlomit Eliashar",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Supervisor in training"
		],
		location: ["St Albans (AL4)", "Works online"],
		area: ["London", "Online"],
		links: ["shlomit0465@gmail.com"],
		imgUrl: new URL(
			"/src/resources/img/generic-user-icon-19.png",
			import.meta.url
		)
	},
	{
		name: "Carlien van Heel",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["London North (N14)", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["carlienvanheel@gmail.com"],
		imgUrl: new URL("/src/resources/img/carlien.png", import.meta.url)
	},
	{
		name: "Jacqualene Ryan",
		qualifications: ["Biodynamic Psychotherapist"],
		location: ["London, South West (TW10)", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["jacqualeneryan@hotmail.com", "https://www.jacqualeneryan.com"],
		imgUrl: new URL("/src/resources/img/cropped.png", import.meta.url)
	},
	{
		name: "Anna Cromack",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["Worcestershire (WR9)", "Works in-person and online"],
		area: ["Midlands", "Online"],
		links: ["anna.cromack@gmail.com", "https://www.annacromack.com"],
		imgUrl: new URL("/src/resources/img/home 5.1.jpg", import.meta.url)
	},
	{
		name: "Jessie Crawford",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London (SE16)", "Works in-person"],
		area: ["London"],
		links: [
			"contactjessiecrawford@gmail.com",
			"https://www.jessietherapeutictouch.com"
		],
		imgUrl: new URL("/src/resources/img/unnamed.jpg", import.meta.url)
	},
	{
		name: "Sarah Gay Rosella",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Trainee Supervisor (not UKCP recognised)"
		],
		location: ["South Devon (TQ9)", "Works in-person and online"],
		area: ["South West", "Online"],
		links: [
			"Sarahgayrosella@gmail.com",
			"https://biodynamicbodypsychotherapy.com"
		],
		imgUrl: new URL("/src/resources/img/sarah.jpg", import.meta.url)
	},
	{
		name: "Miriam Salles",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["Eastbourne (BN21)", "Works in-person and online"],
		area: ["East/South East", "Online"],
		links: ["miriamsalles2@gmail.com", "https://www.miriamsalles.net"],
		imgUrl: new URL("/src/resources/img/miriam.jpg", import.meta.url)
	},
	{
		name: "Peter Kendall",
		qualifications: [
			"Biodynamic Psychotherapist",
			"Biodynamic Massage Therapist"
		],
		location: ["London (SE19)", "Works in-person"],
		area: ["London"],
		links: ["pdjkendall@gmail.com", "https://www.bodyinsight.co.uk"],
		imgUrl: new URL(
			"/src/resources/img/generic-user-icon-19.png",
			import.meta.url
		)
	},
	{
		name: "Ursula Deniflee",
		qualifications: ["Biodynamic Psychotherapist", "Supervisor"],
		location: ["London (SE1)", "Works in-person and online"],
		area: ["London", "Online"],
		links: ["u.deniflee@hotmail.com"],
		imgUrl: new URL(
			"/src/resources/img/generic-user-icon-19.png",
			import.meta.url
		)
	},
	{
		name: "Angelo Frederick",
		qualifications: ["Biodynamic Massage Therapist"],
		location: ["London, W10", "Works in-person"],
		area: ["London"],
		links: [
			"originalspiritlondon@gmail.com",
			"https://originalspiritlondon.com "
		],
		imgUrl: new URL("/src/resources/img/Me3.jpeg", import.meta.url)
	}
];

export default therapists;
