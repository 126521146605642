import styled from "styled-components";
import { colours } from "../common/constants";

const BodyText = styled.div`
	font-family: Helvetica, sans-serif;
	font-size: 14pt;
	line-height: 1.2em;
	color: ${colours.text};
`;

const TitleText = styled(BodyText)`
	margin-bottom: 50px;
	font-size: 32pt;
	line-height: 1.4em;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-weight: bold;
	text-align: center;
	color: #3c434a;

	@media (max-width: 768px) {
		font-size: 24pt;
	  }
`;

const SubheaderText = styled(BodyText)`
	font-size: 2.48em;
	line-height: 1.1em;
	text-align: center;
	font-weight: bold;

	@media (max-width: 768px) {
		font-size: 16pt;
	  }
`;

export { BodyText, SubheaderText, TitleText };
