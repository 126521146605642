import styled from "styled-components";
import { BodyText, TitleText } from "../components/Text";
import { colours } from "../common/constants";

const PageNotFound = () => {
	return (
		<>
			<LargeTitleText>
				4<BlueText>0</BlueText>4
			</LargeTitleText>
			<CenteredBodyText>THIS PAGE COULD NOT BE FOUND</CenteredBodyText>
		</>
	);
};

export default PageNotFound;

const LargeTitleText = styled(TitleText)`
	font-size: 80pt;
`;

const BlueText = styled.span`
	color: ${colours.purple};
`;

const CenteredBodyText = styled(BodyText)`
	text-align: center;
`;
