import Home from "../pages/Home";
import FindATherapist from "../pages/FindATherapist";
import TrainingAndWorkshops from "../pages/TrainingAndWorkshops";
import ContactUs from "../pages/ContactUs";
import MeetTheTeam from "../pages/MeetTheTeam";

/**
 * This document is the source of truth for live pages on the site.
 *
 * This will be used to generate nav menu items, and to create routes for each page
 */

const pages = [
	{ label: "Home", path: "/", element: () => Home() },
	{
		label: "Training & Workshops",
		path: "/training-and-workshops",
		element: () => TrainingAndWorkshops()
	},
	{
		label: "Find A Therapist",
		path: "/find-a-therapist",
		element: () => FindATherapist()
	},
	{
		label: "Contact Us",
		path: "/contact",
		element: () => ContactUs()
	},
	{
		label: "About Us",
		path: "/meet-the-team",
		element: () => MeetTheTeam()
	}
];

export default pages;
