import styled from "styled-components";
import logoImg from "../resources/img/LSBP-Logo.png";
import pages from "../common/page-index";
import { uid } from "../utils";
import { BodyText } from "../components/Text";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
	const navigate = useNavigate();

	return (
		<StyledNavbar>
			<Logo src={logoImg} />
			<Links>
				{pages.map(({ label, path }) => {
					return (
						<Link key={uid()} onClick={() => navigate(path)}>
							<BodyText>{label}</BodyText>
						</Link>
					);
				})}
			</Links>
		</StyledNavbar>
	);
};

export default Navbar;

const StyledNavbar = styled.div`
	width: 70%;
	margin: 0 auto 50px;
	padding-top: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Logo = styled.img`
	width: 100px;
`;

const Links = styled.div`
	column-gap: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;

	@media (max-width: 900px) {
		text-align: right;
		flex-direction: column;
	}
`;

const Link = styled.div`
	font-size: 18pt;
	flex-grow: 1;
	text-align: right;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;
