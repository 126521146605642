import styled from "styled-components";
import { HorizontalRule } from "../components/Decorations";
import { BodyText, SubheaderText, TitleText } from "../components/Text";
import therapists from "../resources/therapist-data/therapists";
import areas from "../resources/therapist-data/areas";
import { uid } from "../utils";
import { colours, mediaQueries } from "../common/constants";
import Footer from "../components/Footer";

const FindATherapist = () => {
	return (
		<>
			<TitleText>Find A Therapist</TitleText>
			{areas.map((area) => {
				return (
					<div key={uid()}>
						<HorizontalRule />
						<SubheaderText>{area}</SubheaderText>
						<TherapistCardContainer key={uid()}>
							{therapists
								.filter((t) => t.area.includes(area)) // filter therapists by given area
								.map((t) => {
									// list all filtered therapists
									return (
										<TherapistCard
											name={t.name}
											qualifications={t.qualifications}
											location={t.location}
											links={t.links}
											phone={t.phone}
											imgUrl={t.imgUrl}
											key={uid()}
										/>
									);
								})}
						</TherapistCardContainer>
					</div>
				);
			})}
			<Footer />
		</>
	);
};

export default FindATherapist;

/** Other Components */
const TherapistCard = ({
	name,
	qualifications,
	location,
	links,
	imgUrl,
	phone
}) => {
	return (
		<StyledTherapistCard>
			<TextContainer>
				<TherapistName>{name}</TherapistName>

				<DescriptionText>
					{qualifications.map((q) => {
						return (
							<div key={uid()}>
								{q}
								<br />
							</div>
						);
					})}
				</DescriptionText>

				<DescriptionText>
					{location.map((l) => {
						return (
							<div key={uid()}>
								{l}
								<br />
							</div>
						);
					})}
				</DescriptionText>
				<DescriptionText>{phone ? phone : null}</DescriptionText>
				<DescriptionText>
					{links.map((link) => {
						return link.includes("@") ? (
							<a
								target="_blank"
								rel="noreferrer"
								href={`mailto:${link}`}
								key={uid()}>
								<Link>{link}</Link>
							</a>
						) : (
							<a
								target="_blank"
								rel="noreferrer"
								href={link}
								key={uid()}>
								<Link>{link}</Link>
							</a>
						);
					})}
					<br />
				</DescriptionText>
			</TextContainer>
			<Portrait src={imgUrl} />
		</StyledTherapistCard>
	);
};

/** Styled Components */

const TherapistCardContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;

const StyledTherapistCard = styled.div`
	display: flex;
	margin-top: 40px;
	justify-content: center;

	height: 300px;
	width: 450px;

	${mediaQueries.largeScreens} {
		width: 400px;
	}

	${mediaQueries.extraSmallScreens} {
		flex-direction: column;
		text-align: center;
		height: fit-content;
		margin: 80px 0 0;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 60%;
	word-wrap: break-word;

	${mediaQueries.extraSmallScreens} {
		width: 100%;
		text-align: center;
		height: fit-content;
	}
`;

const DescriptionText = styled(BodyText)`
	font-size: 12pt;
`;

const TherapistName = styled(SubheaderText)`
	font-size: 18pt;
	text-align: left;
	line-height: 1em;

	${mediaQueries.extraSmallScreens} {
		text-align: center;
	}
`;

const Portrait = styled.img`
	display: block;
	object-fit: cover;
	object-position: top;
	width: 40%;

	${mediaQueries.extraSmallScreens} {
		width: 200px;
		height: 250px;
		margin: 0 auto;
	}
`;

const Link = styled.div`
	color: ${colours.purple};
	text-wrap: balance;
`;
